@import '@amplify/styles/scss/amplify/_colors.scss';

.confirmation-modal-header {
    span {
        color: $primary;
        font-weight: 700;
    }
}

.modal-confirmation-checkbox-wrapper {
    color: $text-color-lighter;

    &.confirmed {
        color: inherit;
    }
}

.confirmation-modal-actions {
    button {
        margin-left: 16px;
    }

    .btn[disabled] {
        background-color: $text-color-lighter;
        border-color: transparent;
        outline: none;
    }
}
