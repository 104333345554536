.tutoring-portal-greetings {
    margin-bottom: 48px;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    p {
        width: 60%;

        @media screen and (max-width: 980px) {
            width: 85%;
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}
