@import '@amplify/styles/scss/amplify/_variables.scss';

.tutoring-portal-header {
    min-width: 360px;
    width: 100%;
    height: 60px;

    & > .container {
        height: 60px;
        display: flex;
        align-items: center;

        svg {
            height: auto;
            max-width: 200px;
            display: inline-block;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.3125rem #9c0d63;
            }
        }

        .tutoring-portal-logo-title {
            font-size: 1.4rem;
            margin: auto 20px 5px;
            font-family: $font-family-sans-serif;
            word-spacing: 0.005em;

            @media screen and (max-width: 479px) {
                display: none;
            }
        }

        & > .tutoring-portal-active-district {
            margin-left: auto;
            display: flex;
            align-items: center;
        }
    }

    .cursor {
        cursor: pointer;
    }

    .active-label {
        background-color: #E9E9E9;
        padding: 5px;
        border-radius: 2px;
        text-transform: uppercase;
        margin-right: 12px;
        display: flex;
        align-items: center;
        color: var(--text-02-text-light, #676767);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 146.667% */

        .dot {
            height: 18px;
            width: 18px;
            background-color: #00866B;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
        }
    }

    .tutoring-portal-link {
        font-size: 15px;
    }
}
