@import '@amplify/styles/scss/amplify/_colors.scss';
@import '@amplify/styles/scss/amplify/_variables.scss';

.tutoring-portal-district {
    margin-top: 32px;
    box-shadow: 0px 0px 3px 1px $gray-2;
    border-radius: 2px;

    .skeleton {
        display: inline-block;
        position: relative;
        overflow: hidden;
        background-color: $gray-1;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                    90deg,
                    rgba($gray-3, 0) 0,
                    rgba($gray-3, 0.2) 20%,
                    rgba($gray-3, 0.5) 60%,
                    rgba($gray-3, 0)
            );
            animation: shimmer 3s infinite;
            content: '';
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }

    &.active {
        box-shadow: 0 0 2px 2px $green;
    }

    .tutoring-portal-district-header {
        display: flex;
        align-items: center;
        height: 60px;
        font-family: $font-family-sans-serif;
        background-color: $gray-1;
        padding-right: 24px;

        &.substitute {
            border-radius: var(--radius_0, 3px) var(--radius_0, 3px) 0px 0px;
            background: var(--messaging-blue-02-blue-lightest, #EBF2FA);
        }

        .tutoring-portal-active-district-wrapper {
            display: flex;
            align-items: center;

            .active-label {
                background-color: #E9E9E9;
                padding: 5px;
                border-radius: 2px;
                text-transform: uppercase;
                margin-right: 12px;
                display: flex;
                align-items: center;
                color: var(--text-02-text-light, #676767);
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 146.667% */

                .dot {
                    height: 18px;
                    width: 18px;
                    background-color: #00866B;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 10px;
                }
            }

            .tutoring-portal-link {
                line-height: 2em;

                .simple-link {
                    color: $amplify-gray;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .logout-link {
                text-decoration: underline;
            }
        }


        h3 {
            margin: 0 24px;
            color: var(--text-01-text, #3B3B3B);
            font-size: 25.92px;
            font-style: normal;
            font-weight: 350;
            line-height: 33px; /* 127.315% */
        }

        .tutoring-portal-link {
            font-size: 0.95rem;

            &:nth-child(3) {
                margin-left: auto;
                margin-right: 12px;
            }
        }

        &.tutoring-portal-border-bottom {
            border-bottom: 1px solid $gray-3;
        }

        @media screen and (max-width: 767px) {
            display: block;
            height: auto;

            h3 {
                margin: 12px 0;
            }

            .tutoring-portal-link {
                margin-left: 0;
                margin-bottom: 12px;
            }
        }
    }

    .totoring-portal-join-session {
        display: block;
        margin: 12px 0;
        padding: 0 24px;
    }

    ul.tutoring-portal-sessions {
        list-style: none;
        margin: 0 -24px 24px -12px;
        padding: 0 12px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        @media screen and (max-width: 1024px) {
            margin-right: -18px;
        }

        @media screen and (max-width: 768px) {
            margin-right: -12px;
        }

        li {
            width: 33%;
            margin-bottom: 4px;
            padding: 0 12px 12px 12px;
            
            @media screen and (max-width: 768px) {
                width: 50%;
            }

            @media screen and (max-width: 480px) {
                width: 100%;
            }

            .tutoring-portal-session-card {
                display: flex;
                flex-direction: column;
                gap: 18px;
                padding: 12px;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15); 
                min-height: 210px;
                position: relative;
                border-radius: 2.81px;
                border: 1px solid var(--gray-03-gray-3, #BDBDBD);

                button {
                    display: block;
                    max-width: 100%;
                    text-overflow: ellipsis;
                    text-align: left;
                    overflow: hidden;
                    white-space: nowrap;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding-left: 0;

                    color: var(--text-01-text, #3B3B3B);
                    font-size: 19.2px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 125% */
                }

                span {
                    display: inline-block;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 176% */
                }

                &.hovered:hover {
                    box-shadow: 1px 1px 3px 2px $gray-3;
                }

                &.cursor-pointer {
                    cursor: pointer;
                }

                .title-container {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

                .text-title {
                    font-size: 19.2px; 
                    font-weight: 500;

                    &.disabled {
                        opacity: 0.4;
                    }
                }
                .text-normal {
                    color: var(--text-01-text, #3B3B3B);
                    font-size: 15px;
                    white-space: wrap;
                }
                .text-normal-light {
                    color: var(--text-02-text-light, #676767);
                    font-size: 15px;
                }
                .text-small {
                    color: var(--text-02-text-light, #676767);
                    font-size: 12.5px;
                }
                .text-italic {
                    font-style: italic;
                }
                .dates-container {
                    display: flex;
                    flex:1;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 11px;

                    .date {
                        display: flex;
                        gap: 6px;
                        align-items: center;
                    }
                    .date-icon {
                        width: 24px;
                        height: 24px;
                    }
                } 

                .today-sign {
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    font-size: 16px;
                    color: var(--text-01-text, #3B3B3B);
                    background: #FBD5BC;
                    border-radius: 3px;
                    padding: 8.5px 13px;
                }
                .next-session-time-container {
                    display: flex;
                    flex-direction: column;
                    gap: 11px;
                }
            }
        }
    }

    & > .tutoring-portal-link {
        text-align: right;

        .see-all-my-groups-button {
            color: $text-color;
            margin: 0 12px 12px 12px;

            i {
                vertical-align: top;
            }
        }
    }
}

// Override toggle component styles
.faux-input {
    .toggle-button {
        height: 2.1em !important;

        &::after {
            height: 1.9em !important;
            width: 1.9em !important;
        }
    }
}
