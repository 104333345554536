@import '@amplify/styles/scss/amplify/_colors.scss';

.tutoring-portal-link {
    &.uppercase {
        text-transform: uppercase;
    }

    &.default-link {
        color: $amplify-gray;
        text-decoration: none;
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        i {
            vertical-align: text-bottom;
            margin-right: 4px;
        }
    }

    &.simple-link {
        color: $blue;
        text-decoration-color: $blue;
    }

    &.cursor-pointer {
        cursor: pointer;
    }
}
