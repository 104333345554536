$font-path: '~@amplify/styles/fonts';

@import '@amplify/styles';
@import '@amplify/styles/scss/amplify/_colors.scss';

.tutoring-portal-border-bottom {
    border-bottom: 2px solid $gray-2;
}

.word-wrap {
    word-wrap: break-word;
}
