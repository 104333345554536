.tutoring-portal-districts {
    .amplify-toggle {
        max-width: 80px;
        .faux-input {
            max-width: 80px;
        }
    }
    .toggle-button {
        max-width: 80px;
    }
    .text-off {
        &::after {
            padding-left: 20px !important;
            padding-right: 0px !important;
        }
    }
    .text-on {
        &::after {
            padding-left: 0px !important;
            padding-right: 20px !important;
        }
    }
}
