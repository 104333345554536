@import '@amplify/styles/scss/amplify/_colors.scss';

.tutoring-portal-logo {
    .cls-1 {
        fill: $amplify-orange;
    }

    .cls-2 {
        fill: $amplify-wordmark-gray;
    }
}
