.time-container {
    text-align: right;
}

.time-container-date-title {
    color: var(--text-01-text, #3b3b3b);
    font-size: 21.6px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 152.778% */
    background: #fbd5bc;
    border-radius: 2px;
    padding: 5px 11px;
}

.time-container-localtime-title {
    color: var(--text-02-text-light, #676767);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    white-space: nowrap;
    margin-top: 22px;
    display: flex;
    align-items: center;
}
